export const colors = {
  black: "#0f132a",
  white: "#FFFFFF",
  gray: "#F3F3F3",
  grayBackground: "#",
  primary: "#191e39",
  primaryLight: "#1E285F",
  secondary: "#e72f76",
  accent: "#fe9513",
}

export const elements = {
  headerBackground: colors.primary,
  headerFont: colors.white,

  title: colors.black,
  titleUnderline: colors.secondary,

  subtitleFont: colors.gray3,
  subtitleUnderline: colors.secondary,


  heroBackround: colors.primary,

  footerBackground: colors.primary,
  footerFont: colors.accent,
  footerIcon: colors.accent,

  AboutUsBackground: colors.primary,
  AboutUsText: colors.gray1,
  AboutUsCTABackground: colors.secondary,
  AboutUsCTAText: colors.white,
  AboutUsCTATitleFont: colors.white,
  AboutUsCTASubtitleFont: colors.gray1,

  ServicesBackground: colors.white,
  ServicesText: colors.gray1,

  HeroTitle: colors.white,
  HeroText: colors.white,
  HeroAccent: colors.secondary,

  CardBackground: "#FFE6E6",
  CardTitle: colors.black,
  CardText: "#797B86",
  CardAccent: colors.secondary,
}

export const spaces = {
  horizontalPadding: "13vw",
}